import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from 'components/Layout';
import NFBodyRenderer, { IExpectedStructures } from 'components/common/NFBodyRenderer';
import SymptomsIllustrations from 'components/SymptomsIllustrations';
import Banner from 'components/Banner';
import FeaturedArticles from 'components/FeaturedArticles';
import WhyNurofen from 'components/WhyNurofen';
import FeaturedProducts from 'components/FeaturedProducts';
import VideoSection from 'components/PodcastsPageComponents/VideoSection';
import SimpleSection from 'components/PodcastsPageComponents/SimpleSection';
import ExtendedFeaturedArticles from 'components/ExtendedFeaturedArticles';
import PainBanner from 'components/PainBanner';
import PainBannerExtendTitle from 'components/PainBannerExtendTitle';
import PromotionHeroBanner from 'components/PromotionHeroBanner';
import PainHomeBanner from 'components/PainHomeBanner';

import parseUmbracoHomePageIncomeData from './parsers';
import { IUmbracoHomePageIncomeData, IUmbracoHomePageParsedData } from './models';

import './HomePage.scss';

interface PropsHomePage extends PageProps {
  data: IUmbracoHomePageIncomeData;
  pageContext: { featuredProductsLinks: string[] };
}

const ExpectedStructures: IExpectedStructures = {
  'Symptoms List': {
    Component: SymptomsIllustrations,
    dataKey: 'symptomsList',
  },
  'Page Banner': {
    Component: Banner,
    dataKey: 'banner',
  },
  'Featured Articles': {
    Component: FeaturedArticles,
    dataKey: 'relatedArticles',
  },
  'Info Banner': {
    Component: WhyNurofen,
    dataKey: 'whyNurofen',
  },
  'Featured Products': {
    Component: FeaturedProducts,
    dataKey: 'featuredProducts',
  },
  'Video Section': {
    Component: VideoSection,
    dataKey: 'videoSection',
  },
  'Featured Articles From Symptoms & Pain Advice Sections': {
    Component: ExtendedFeaturedArticles,
    dataKey: 'extendedFeaturedArticles',
  },
  'Simple Section': {
    Component: SimpleSection,
    dataKey: 'simpleSection',
  },
  'Pain Banner': {
    Component: PainBanner,
    dataKey: 'painBanner',
  },
  'Pain Banner Extend Title': {
    Component: PainBannerExtendTitle,
    dataKey: 'painBannerExtendTitle',
  },
  'Promotion Hero Banner': {
    Component: PromotionHeroBanner,
    dataKey: 'promotionHeroBanner',
  },
  'Pain Home Banner': {
    Component: PainHomeBanner,
    dataKey: 'painHomeBanner',
  },
};

const HomePage: FC<PropsHomePage> = ({ data, pageContext: { featuredProductsLinks } }) => {
  const parsedData: IUmbracoHomePageParsedData = parseUmbracoHomePageIncomeData({
    ...data,
    featuredProductsLinks,
  });
  const {
    umbracoHome: {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      lang,
      pageName,
      ogImage,
    },
    siteSettings,
    header,
    footer,
    mobileAppPromoBanner,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    promoModal,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  } = data;

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      }}
      siteSettings={siteSettings}
      header={header}
      footer={footer}
      mobileAppPromoBanner={mobileAppPromoBanner}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      langSettings={{
        currentLang: lang,
        langs,
      }}
      promoModal={promoModal}
      legalPopup={legalPopup}
      className="home-page"
    >
      <NFBodyRenderer
        bodyStructure={data.umbracoHome.body}
        expectedStructures={ExpectedStructures}
        parsedData={parsedData}
        additionalBodyProps={{ listingName: pageName }}
      />
    </Layout>
  );
};

export const query = graphql`
  query(
    $lang: String!
    $link: String = ""
    $articleTagId: Int
    $featuredProductsLinks: [String]
    $pageBannerImageSize: Int = 1000
    $pageIdRegex: String
    $extendedFeaturedArticlesByTagIds: [Int]
    $extendedFeaturedArticlesByLinks: [String]
  ) {
    featuredArticlesByTag: allUmbracoArticles(
      filter: { tags: { elemMatch: { id: { eq: $articleTagId } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    ...FragmentExtendedFeaturedArticlesData
    featuredProducts: allUmbracoProduct(
      filter: { link: { in: $featuredProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    umbracoHome(lang: { eq: $lang }) {
      ...FragmentSeo
      lang
      pageName
      body {
        structure
        properties {
          ...FragmentSymptomsIllustrations
          ...FragmentBanner
          ...FragmentFeaturedArticles
          ...FragmentWhyNurofen
          ...FragmentFeaturedProducts
          ...FragmentExtendedFeaturedArticles
          ...FragmentVideoSection
          simpleSection {
            properties {
              ...FragmentSimpleSection
            }
          }
          ...FragmentPainBanner
          ...FragmentPainBannerExtendTitle
          ...FragmentPromotionHeroBanner
          ...FragmentPainHomeBanner
        }
      }
    }
    pageLangs: allUmbracoHome(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default HomePage;
